<template>
  <div>
    <section id="AwardList">
      <v-container>
        <div class="titleDiv px-5">
          <p class="display-1">Awards</p>
        </div>
        <div>
          <div
            class="d-flex align-center justify-center text-center my-5"
            style="flex-direction: column"
          >
            <p class="display-1">Congratulations to all Winners!</p>
            <p class="subtitle-1">Incentivising learning is the key to a student's success</p>
          </div>

          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="4"
              class="d-flex align-center justify-center"
              height="100%"
            >
              <v-card
                dark
                class="d-flex align-center indigo q-card pa-5 justify-center text-center"
                height="100%"
                width="100%"
                @click="$router.push('/awardoverview/11')"
              >
                <v-card-text class="pa-5">
                  <!-- <p class="display-1">Q1</p> -->
                  <p class="headline">November</p>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="4"
              class="d-flex align-center justify-center"
              height="100%"
            >
              <v-card
                dark
                class="d-flex align-center teal darken-2 q-card pa-5 justify-center text-center"
                height="100%"
                width="100%"
                @click="$router.push('/awardoverview/12')"
              >
                <v-card-text class="pa-5">
                  <!-- <p class="display-1">Q2</p> -->
                  <p class="headline">December</p>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="4"
              class="d-flex align-center justify-center"
              height="100%"
            >
              <v-card
                dark
                class="d-flex align-center q-card deep-purple pa-5 justify-center text-center"
                height="100%"
                width="100%"
                @click="$router.push('/awardoverview/1')"
              >
                <v-card-text class="pa-5">
                  <!-- <p class="display-1">Q3</p> -->
                  <p class="headline">January</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  name: "awardlist"
};
</script>

<style scoped>
#AwardList {
  padding-top: 7rem !important;
  padding-bottom: 5rem !important;
  color: #eee;
}
.titleDiv {
  border-left: 10px solid #eee;
  margin-bottom: 5rem;
}
.q-card {
  border-radius: 20px !important;
}
.q-card .display-1,
.q-card .headline {
  text-shadow: -1px 1px 5px rgba(0, 0, 0, 0.5);
}
</style>